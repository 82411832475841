import { Fragment, h, JSX } from "preact";

export default function AppSuperHeader(): JSX.Element {
  return (
    <Fragment>
      <div className={"obesidadeinfantil-menus"}>
        <div className={"container"}>
          <ul>
            <li>
              <a href={"https://desiderata.org.br/estrategias/panorama-da-obesidade-de-criancas-e-adolescentes/"}>sobre</a>
            </li>
            <li>
              <a href={"/"}>panorama</a>
            </li>
            <li>
              <a href={"/publicacoes.html"}>publicações</a>
            </li>
            <li>
              <a href={"/noticias.html"}>notícias</a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                href={"https://www.instagram.com/institutodesiderata/"}
                title={"Instagram"}
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt={"Instagram"} src={"/assets/img/social/instagram.png"} width={30} height={30} />
              </a>
            </li>
            <li>
              <a
                href={"https://www.facebook.com/institutodesiderata/"}
                title={"Facebook"}
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt={"Facebook"} src={"/assets/img/social/facebook.png"} width={30} height={30} />
              </a>
            </li>
            <li>
              <a
                href={"https://www.youtube.com/channel/UCf1gyibTyKLAGsgG_zH4kOw"}
                title={"Youtube"}
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt={"Youtube"} src={"/assets/img/social/youtube.png"} width={30} height={30} />
              </a>
            </li>
            <li>
              <a
                href={"https://www.linkedin.com/company/instituto-desiderata"}
                title={"Linkedin"}
                target={"_blank"}
                rel="noreferrer"
              >
                <img alt={"Linkedin"} src={"/assets/img/social/linkedin.png"} width={30} height={30} />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className={"obesidadeinfantil-logos"}>
        <div className={"container"}>
          <a href={"/"}>
            <img
              alt={"Logo Obesidade Infantil"}
              src={"/assets/img/logos/obesidadeinfantil.png"}
              width={321}
              height={110}
              class={'img-logo-panorama'}
            />
          </a>

          <a href={"https://desiderata.org.br/"} target={"_blank"} rel="noreferrer">
            <span>Realização:</span>
            <img
              alt={"Logo Instituto Desiderata"}
              src={"/assets/img/logos/desiderata.png"}
              width={180}
              height={111}
              class={'img-logo-desiderata'}
            />
          </a>
        </div>
      </div>
    </Fragment>
  );
}
